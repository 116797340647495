import React from 'react'
// import { Link } from "gatsby"
import '../components/styles.css'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import Layout from '../components/templates/layout'
import Seo from '../components/seo'
// import Image from '../components/image'

const FullHeightModule = styled.section`
  ${tw` bg-blue-900
        text-white
        items-center
        flex
        flex-col
        justify-center
        text-center
        px-1`}
        min-height:80vh
`

const Name = styled.h1`
  ${tw`text-gray-100
        text-5xl
        antialiased
        tracking-wider`}
  transition: 1s all;
  &:hover {
    opacity: 0.7;
  }

`

const SocialLink = styled.a`
  ${tw`
    text-white
    pt-6
  `}
  transition: 1s all;
  &:visited {
    color:#fff;
  }
  &:hover {
    color: #4e637f;
  }
`

const Engagements = styled.section`
  ${tw`
      bg-white
      text-3xl
      antialiased
      tracking-wider
      font-bold
      text-blue-900
      flex
      justify-center
      items-center
      flex-col
      text-center
    `}
`

const Video = styled.iframe`
  flex: 1 1 auto;
  border: 0;
`

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  max-width:850px;
  padding-top: 1vh
  min-height: 0;
`

const IndexPage = () => (
  <Layout>
    <Seo title='John Lamb' />
    <FullHeightModule>
      <Name>John Lamb</Name>
      <p>Hi, i'm John. I'm a Software Engineer specializing in DevOps and platform migrations.</p>
      <p>Let's chat about High Availability, Docker, and Infrastructure as Code:</p>
      <SocialLink href='https://www.linkedin.com/in/john-lamb-5925563b'
        target='_blank' rel='noopener noreferrer'>LinkedIn</SocialLink>
    </FullHeightModule>
    {/* <Engagements>
      <p><a href='https://dotall.com/sessions/david-and-goliath-how-a-bespoke-cms-can-compete-and-beat-industry-leaders'
        target='_blank' rel='noopener noreferrer'>DotAll</a> Craft CMS: Montréal.</p>

      <p><a href="https://vimeo.com/365635348">David and Goliath: How a bespoke CMS can compete (and beat) industry leaders with John Lamb</a> from <a href="https://vimeo.com/craftcms">Craft CMS</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
    </Engagements> */}
    <Engagements>
      <VideoWrapper>
        <Video src="https://player.vimeo.com/video/365635348?title=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen loading="lazy"></Video><script src="https://player.vimeo.com/api/player.js"></script>
      </VideoWrapper>
    </Engagements>
  </Layout >
)

export default IndexPage
